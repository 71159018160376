
import { Course, ReviewCollection } from "@planetadeleste/vue-mc-learning";
import { Vue, Component, Prop } from "vue-property-decorator";
import CourseReviewCommentItem from "@/modules/courses/components/course/CourseReviewCommentItem.vue";
import { EventBus } from "@/services/event-bus";

@Component({
  components: { CourseReviewCommentItem },
})
export default class CourseReviewComments extends Vue {
  @Prop(Object) readonly item!: Course;
  obReviews: ReviewCollection = new ReviewCollection();

  mounted(): void {
    EventBus.on("course.review.updated", this.load);
    this.$nextTick(this.load);
  }

  beforeDestroy(): void {
    EventBus.off("course.review.updated");
  }

  async load(): Promise<void> {
    if (!this.item || !this.item.id) {
      return;
    }

    this.obReviews.clear();
    this.obReviews.page(1);
    await this.obReviews.filterBy({ course: this.item.id }).fetch();
  }
}
