var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.review && _vm.review.preview_text.length)?_c('div',{staticClass:"course__comment-box"},[_c('div',{staticClass:"course__comment-thumb float-start"},[(_vm.user.avatar)?_c('img',{attrs:{"src":_vm.user.avatar,"alt":_vm.user.name}}):_c('span',{class:[
        'avatar',
        'avatar-48',
        'bg-primary',
        'text-white',
        'rounded-circle',
      ]},[_vm._v(" "+_vm._s(_vm.initials)+" ")])]),_c('div',{staticClass:"course__comment-content"},[_c('div',{staticClass:"course__comment-wrapper ml-70 fix"},[_c('div',{staticClass:"course__comment-info float-start"},[_c('h4',[_vm._v(_vm._s(_vm.user.name))]),_c('span',[_vm._v(_vm._s(_vm.createdAt))])]),(_vm.review.rating)?_c('div',{staticClass:"course__comment-rating float-start float-sm-end"},[_c('star-rating',{attrs:{"star-size":20,"rating":_vm.review.rating,"read-only":""}})],1):_vm._e()]),_c('div',{staticClass:"course__comment-text ml-70"},[_c('p',{domProps:{"textContent":_vm._s(_vm.review.preview_text)}})])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }