
import AuthMixin from "@/mixins/AuthMixin";
import { EventBus } from "@/services/event-bus";
import { Course, Review, ReviewData } from "@planetadeleste/vue-mc-learning";
import { Component, Prop, Mixins } from "vue-property-decorator";
import StarRating from "vue-star-rating";

@Component({
  components: { StarRating },
})
export default class CourseReviewForm extends Mixins(AuthMixin) {
  @Prop(Object) readonly item!: Course;
  iRating = 0;
  sComment = "";

  setRating(iVal: number): void {
    this.iRating = iVal > 5 ? 5 : iVal;
  }

  async review(): Promise<void> {
    const obReview = new Review({
      course_id: this.item.id,
      user_id: this.user.id,
      preview_text: this.sComment,
      rating: this.iRating,
    });

    const obResponse = await obReview.store();
    if (obResponse && obResponse.getData().status) {
      this.iRating = 0;
      this.sComment = "";

      const obReviewData: ReviewData = obResponse.getData().data;
      this.item.set(obReviewData.course);

      EventBus.emit("course.review.updated", this.item.id);
    }
  }

  get canRating(): boolean {
    return this.item.get("can_rating", false);
  }

  get display(): boolean {
    return this.logged && !!this.item && !!this.item.id;
  }

  get valid(): boolean {
    return this.iRating > 0 || this.sComment.length > 1;
  }
}
