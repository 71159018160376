
import { Course } from "@planetadeleste/vue-mc-learning";
import { Vue, Component, Prop } from "vue-property-decorator";
import CourseReviewRating from "@/modules/courses/components/course/CourseReviewRating.vue";
import CourseReviewForm from "@/modules/courses/components/course/CourseReviewForm.vue";
import CourseReviewComments from "@/modules/courses/components/course/CourseReviewComments.vue";

@Component({
  components: { CourseReviewRating, CourseReviewForm, CourseReviewComments },
})
export default class CourseReview extends Vue {
  @Prop(Object) readonly item!: Course;
}
