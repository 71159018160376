
import { Course } from "@planetadeleste/vue-mc-learning";
import { Vue, Component, Prop } from "vue-property-decorator";
import StarRating from "vue-star-rating";

@Component({
  components: { StarRating },
})
export default class CourseReviewRating extends Vue {
  @Prop(Object) readonly item!: Course;

  get rating(): number {
    return this.item.get("rating");
  }

  get ratingCount(): number {
    return this.item.get("rating_count");
  }

  get ratingDetails(): Record<string, any>[] {
    return this.$_.reverse(this.item.get("rating_details", []));
  }

  get stars(): number {
    return this.ratingDetails.length;
  }
}
