
import { Course, ReviewData } from "@planetadeleste/vue-mc-learning";
import { UserData } from "@planetadeleste/vue-mc-shopaholic";
import { Vue, Component, Prop } from "vue-property-decorator";
import StarRating from "vue-star-rating";

@Component({
  components: { StarRating },
})
export default class CourseReviewCommentItem extends Vue {
  @Prop(Object) readonly review!: ReviewData;
  @Prop(Object) readonly course!: Course;

  get user(): UserData {
    return this.review.user;
  }

  get createdAt(): string {
    return !!this.review && !!this.review.created_at
      ? this.$moment(this.review.created_at).format("ll")
      : "";
  }

  get initials(): string | null {
    return this.user
      ? this.$_.chain(`${this.user.name} ${this.user.last_name}`)
          .trim()
          .words()
          .filter((word) => word.length > 0)
          .map((word) => word.substring(0, 1))
          .join("")
          .toUpper()
          .truncate({ omission: "", length: 2 })
          .value()
      : null;
  }
}
